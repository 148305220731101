import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Box, FormControl, InputLabel, Select, MenuItem, Typography, CircularProgress } from '@mui/material';

function VotesTab({ id }) {
  const [rollCalls, setRollCalls] = useState([]);
  const [selectedRollCallId, setSelectedRollCallId] = useState('');
  const [votesData, setVotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('candidate_name');
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    fetchRollCalls(id);
  }, [id]);

  const fetchRollCalls = (id) => {
    fetch(`https://api.civiclyenvolved.com/api/bill-roll-calls/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setRollCalls(data);
        if (data.length > 0) {
          setSelectedRollCallId(data[0].id);
          setVotesData(data[0].votes);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching roll calls:', error);
        setIsLoading(false);
      });
  };

  const handleRollCallChange = (event) => {
    const selectedId = event.target.value;
    setSelectedRollCallId(selectedId);

    const selectedRollCall = rollCalls.find(rollCall => rollCall.id === selectedId);
    if (selectedRollCall) {
      setVotesData(selectedRollCall.votes);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedVotes = (votes) => {
    return [...votes].sort((a, b) => {
      const aValue = a[orderBy] ?? '';
      const bValue = b[orderBy] ?? '';
      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {rollCalls.length > 0 && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="roll-call-select-label">Roll Calls</InputLabel>
              <Select
                labelId="roll-call-select-label"
                value={selectedRollCallId}
                label="Roll Calls"
                onChange={handleRollCallChange}
              >
                {rollCalls.map((rollCall) => (
                  <MenuItem key={rollCall.id} value={rollCall.id}>
                    {rollCall.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {votesData.length === 0 ? (
            <Typography>No Votes</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650, border: '1px solid #E0E0E0' }} aria-label="votes table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#E0E0E0' }}>
                    <TableCell sx={{ border: '1px solid #E0E0E0' }}>
                      <TableSortLabel
                        active={orderBy === 'sponsor'}
                        direction={orderBy === 'sponsor' ? order : 'asc'}
                        onClick={() => handleRequestSort('sponsor')}
                      >
                        Sponsor
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #E0E0E0' }}>
                      <TableSortLabel
                        active={orderBy === 'candidate_name'}
                        direction={orderBy === 'candidate_name' ? order : 'asc'}
                        onClick={() => handleRequestSort('candidate_name')}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ border: '1px solid #E0E0E0' }}>
                      <TableSortLabel
                        active={orderBy === 'vote'}
                        direction={orderBy === 'vote' ? order : 'asc'}
                        onClick={() => handleRequestSort('vote')}
                      >
                        Vote
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(votesData) && sortedVotes(votesData).map((vote, voteIndex) => (
                    <TableRow key={voteIndex}>
                      <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.sponsor}</TableCell>
                      <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.candidate_name}</TableCell>
                      <TableCell sx={{ border: '1px solid #E0E0E0' }}>{vote.vote}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}
    </Box>
  );
}

export default VotesTab;