import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import hasFeatureAccess from './utils/featureAccess';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Button } from '@mui/material';

function UpcomingPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [bills, setBills] = useState([]);
    const userId = localStorage.getItem('user_id');
    const [votes, setVotes] = useState([]);

    const icons = {};
    icons['Strongly Disagree'] = {'emoji': '🤯', 'color': '#F99E17'};
    icons['Disagree'] =  {'emoji': '😕', 'color': '#FFE500'};
    icons['Neutral'] =  {'emoji': '😐', 'color': '#949494'};
    icons['Agree'] =  {'emoji': '🙂', 'color': '#00E709'};
    icons['Strongly Agree'] =  {'emoji': '🥳', 'color': '#00A006'};
  
    useEffect(() => {
        const fetchBills = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/bills/search/${userId}/?q=&page=1&status=Introduced`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setBills(data.bills);

                data.bills.forEach(bill => {
                    if (bill.vote_info) {
                        setVotes(prevVotes => ({
                            ...prevVotes,
                            [bill.id]: { key: bill.vote_info.vote_type }
                        }));
                    }
                });


            } catch (error) {
                console.error('Error fetching bills:', error);
            }
        };

        fetchBills();
    }, [userId]);

    useEffect(() => {
        if (!hasFeatureAccess('upcoming')) {
            navigate('/access-denied');
        }
    }, [navigate]);

    const [sortConfig, setSortConfig] = useState({ key: 'bill_number', direction: 'asc' });

    const sortedBills = React.useMemo(() => {
        let sortableBills = [...bills];
        if (sortConfig !== null) {
            sortableBills.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableBills;
    }, [bills, sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleClick = async (bill_id, key) => {
        setVotes(prevVotes => ({
            ...prevVotes,
            [bill_id]: { key }
        }));
        
        const voteData = {
          user_id: userId,
          source_id: bill_id,
          source: 'bill',
          vote: key
        };
    
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/votes/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
            },
            body: JSON.stringify(voteData),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const responseData = await response.json();
          console.log('Vote submitted successfully:', responseData);
        } catch (error) {
          console.error('Error submitting vote:', error);
        }
      };

    return (
        <Layout searchQuery={searchQuery}>
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sortDirection={sortConfig.key === 'bill_number' ? sortConfig.direction : false}>
                                <TableSortLabel
                                    active={sortConfig.key === 'bill_number'}
                                    direction={sortConfig.key === 'bill_number' ? sortConfig.direction : 'asc'}
                                    onClick={() => requestSort('bill_number')}
                                >
                                    Bill Number
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={sortConfig.key === 'title' ? sortConfig.direction : false}>
                                <TableSortLabel
                                    active={sortConfig.key === 'title'}
                                    direction={sortConfig.key === 'title' ? sortConfig.direction : 'asc'}
                                    onClick={() => requestSort('title')}
                                >
                                    Title
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={sortConfig.key === 'description' ? sortConfig.direction : false}>
                                <TableSortLabel
                                    active={sortConfig.key === 'description'}
                                    direction={sortConfig.key === 'description' ? sortConfig.direction : 'asc'}
                                    onClick={() => requestSort('description')}
                                >
                                    Description
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sortDirection={sortConfig.key === 'myVote' ? sortConfig.direction : false}>
                                <TableSortLabel>
                                    My Vote
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedBills && sortedBills.map((bill) => (
                            <TableRow key={bill.id}>
                                <TableCell><a href={`/bill/${bill.id}`} target="_blank" rel="noopener noreferrer">{bill.bill_number}</a></TableCell>
                                <TableCell>{bill.title}</TableCell>
                                <TableCell>{bill.description}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        {Object.entries(icons).map(([key, { emoji, color }], index) => (
                                        <Tooltip key={index} title={key} arrow>
                                            <Button
                                            key={index}
                                            onClick={() => handleClick(bill.id, key)}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 60,
                                                height: 60,
                                                fontSize: 30,
                                                border: '1px solid #E0E0E0',
                                                borderRadius: '50%',
                                                backgroundColor: votes[bill.id] && votes[bill.id]['key'] === key ? color : '#f0f0f0',
                                                '&:hover': {
                                                backgroundColor: color,
                                                },
                                            }}
                                            >
                                            {emoji} 
                                            </Button>
                                        </Tooltip>
                                        ))}
                                    </Box>

                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </Layout>
    );
}

export default UpcomingPage;