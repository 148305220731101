import React, { useState } from 'react';
import { Box, Avatar, Typography, TextField, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useParams } from 'react-router-dom';

function ResetPasswordPage() {
  const { uidb64, token } = useParams();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newPassword = data.get('password');
    const reNewPassword = data.get('passwordConfirm');

    if (!newPassword || !reNewPassword) {
        setError('Both password fields are required');
        return;
    }

    if (newPassword !== reNewPassword) {
        setError('Passwords do not match');
        return;
    }

    const formData = {
        new_password: newPassword,
        re_new_password: reNewPassword,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/password-reset-confirm/${uidb64}/${token}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError('We were unable to process your reset request.');
      } else {
        setSuccess(true);
        setError(''); // Clear error if request is successful
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An unexpected error occurred');
    }
  };

  return (
    <Box
        sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
            Reset Password
        </Typography>

        {success ? (
            <Typography component="p" variant="body1" color="success">
                Your password has been reset. Click <a href="/home">here</a> to login.
            </Typography>
        ) : (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="passwordConfirm"
                    label="Confirm Password"
                    type="password"
                    id="passwordConfirm"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Reset Password
                </Button>

                {error && (
                    <Typography component="p" variant="body2" color="error">
                        <b>{error}</b>
                    </Typography>
                )}
            </Box>
        )}
    </Box>
  );
}

export default ResetPasswordPage;