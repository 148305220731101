import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, CircularProgress } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import hasFeatureAccess from './utils/featureAccess';

function NotificationPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([]);
    const [tracking, setTracking] = useState([]);
    const [loading, setLoading] = useState(false);
    const userId = localStorage.getItem('user_id');
    const navigate = useNavigate();

    useEffect(() => {
        if (!hasFeatureAccess('notifications')) {
          navigate('/access-denied');
        }
      }, [navigate]);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag-bill?user_id=${userId}`);
                const ret = await response.json();
                setData(ret['user_tag_bills']);
                setTracking(ret['user_bills']);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }
        fetchData();
    }, [userId]);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('bill.title');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = data.slice().sort((a, b) => {
        let aValue, bValue;

        switch (orderBy) {
            case 'bill.title':
                aValue = a.bill.title;
                bValue = b.bill.title;
                break;
            case 'bill.state':
                aValue = a.bill.state;
                bValue = b.bill.state;
                break;
            case 'bill.bill_number':
                aValue = a.bill.bill_number;
                bValue = b.bill.bill_number;
                break;
            case 'bill.description':
                aValue = a.bill.policy_objective && a.bill.policy_objective.length > 0 ? a.bill.policy_objective : a.bill.description;
                bValue = b.bill.policy_objective && b.bill.policy_objective.length > 0 ? b.bill.policy_objective : b.bill.description;
                break;
            case 'user_tag.tag':
                aValue = a.user_tag.tag;
                bValue = b.user_tag.tag;
                break;
            case 'urgency_level':
                aValue = a.urgency_level;
                bValue = b.urgency_level;
                break;
            case 'level_of_relation':
                aValue = a.level_of_relation;
                bValue = b.level_of_relation;
                break;
            case 'reason':
                aValue = a.reason;
                bValue = b.reason;
                break;
            default:
                return 0;
        }

        if (aValue < bValue) {
            return order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleBookmarkClick = async (billId, action) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/save-user-bill/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                },
                body: JSON.stringify({ bill_id: billId, user_id: userId, action: action }),
            });

            if (response.ok) {
                setTracking((prevTracking) => {
                    if (action === 'add') {
                        return [...prevTracking, billId];
                    } else {
                        return prevTracking.filter((id) => id !== billId);
                    }
                });
            } else {
                console.error('Error saving user bill:', response.statusText);
            }
        } catch (error) {
            console.error('Error saving user bill:', error);
        }
    };

    return (
        <Layout searchQuery={searchQuery}>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {data.length === 0 ? (
                        <p>No New Notifications</p>
                    ) : (
                        <>
                            <h2>We found the following bills based on your tags:</h2>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'bill.title'}
                                                    direction={orderBy === 'bill.title' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('bill.title')}
                                                >
                                                    Title
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'bill.state'}
                                                    direction={orderBy === 'bill.state' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('bill.state')}
                                                >
                                                    State
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'bill.bill_number'}
                                                    direction={orderBy === 'bill.bill_number' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('bill.bill_number')}
                                                >
                                                    Bill Number
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'bill.description'}
                                                    direction={orderBy === 'bill.description' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('bill.description')}
                                                >
                                                    Description
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'user_tag.tag'}
                                                    direction={orderBy === 'user_tag.tag' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('user_tag.tag')}
                                                >
                                                    Tag
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'urgency_level'}
                                                    direction={orderBy === 'urgency_level' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('urgency_level')}
                                                >
                                                    Urgency
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'level_of_relation'}
                                                    direction={orderBy === 'level_of_relation' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('level_of_relation')}
                                                >
                                                    Level of Relation
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell>
                                                <TableSortLabel
                                                    active={orderBy === 'reason'}
                                                    direction={orderBy === 'reason' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('reason')}
                                                >
                                                    Reason
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortedData.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Box display="flex" alignItems="center">
                                                        <Typography onClick={() => handleBookmarkClick(item.bill.id, tracking.includes(item.bill.id) ? 'remove' : 'add')}>
                                                            {tracking.includes(item.bill.id) ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                                                        </Typography>

                                                        <a href={`/bill/${item.bill.id}?tab=1`} target="_blank" rel="noopener noreferrer">
                                                            {item.bill.title}
                                                        </a>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{item.bill.state}</TableCell>
                                                <TableCell>{item.bill.bill_number}</TableCell>
                                                <TableCell>{item.bill.policy_objective && item.bill.policy_objective.length > 0 ? item.bill.policy_objective : item.bill.description}</TableCell>
                                                <TableCell>{item.user_tag.parent ? `${item.user_tag.parent} - ${item.user_tag.tag}` : item.user_tag.tag}</TableCell>
                                                <TableCell>{item.urgency_level}</TableCell>
                                                <TableCell>{item.level_of_relation}</TableCell>
                                                <TableCell>{item.reason}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </Box>
            )}
        </Layout>
    );
}

export default NotificationPage;