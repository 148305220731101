import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Box } from '@mui/material';

function AccessDeniedPage() {
  const [searchQuery, setSearchQuery] = useState(''); 

return (
    <Layout searchQuery={searchQuery}>
        <Box>
            <h1>Access Denied</h1>
            We're sorry but you don't have access to this page. Please contact <a href="mailto:sales@civiclyenvolved.com">sales@civiclyenvolved.com</a> for more information.
        </Box>
    </Layout>
);
}

export default AccessDeniedPage;