import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { Box, Tooltip } from '@mui/material';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import hasFeatureAccess from './utils/featureAccess';

function ChatPage() {
  const [searchQuery, setSearchQuery] = useState(''); 
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [runId, setRunId] = useState(null);
  const [doPoll, setDoPoll] = useState(false);
  const [pollNum, setPollNum] = useState(0);
  const navigate = useNavigate();
  const userId = localStorage.getItem('user_id');
  const messageIds = [];
  const orgPackage = localStorage.getItem('package');
  const [remainingChats, setRemainingChats] = useState(0);

  useEffect(() => {
    if (!hasFeatureAccess('chatbot')) {
      navigate('/access-denied');
    }
  }, [navigate]);

  useEffect(() => {
    if (orgPackage === 'Free') {
      fetch(`${process.env.REACT_APP_API_URL}/chat-remaining/${userId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setRemainingChats(data.remaining_chats);
        })
        .catch(error => console.error('Error fetching remaining chats:', error));
    } else {
      setRemainingChats(100);
    }
  }, [orgPackage, userId]);

  const handleSendMessage = (message) => {
    setRemainingChats(prevRemainingChats => prevRemainingChats - 1);

    setMessages([...messages, { content: message, direction: 'outgoing' }]);

    const thinkingMessage = { content: "We're thinking...", direction: 'incoming' };
    setMessages(prevMessages => [...prevMessages, thinkingMessage]);    
    
    fetch(`${process.env.REACT_APP_API_URL}/chat-all/${userId}?q=${encodeURIComponent(message)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        setRunId(data.run_id);
        setThreadId(data.thread_id);
        setDoPoll(true);
      })
      .catch(error => console.error('Error fetching reply:', error));
  };

  const formatResponse = (text_response, json_data) => {
    let content = text_response;

    if (json_data != null) {
      let bills = '';
      if (Array.isArray(json_data)) {
        for (let i = 0; i < json_data.length; i++) {
          if (json_data[i].link && json_data[i].link.length > 0){
            let queryStr = '';
            if (json_data[i].original_text) {
              queryStr = "&originalText=" + encodeURIComponent(json_data[i].original_text);
            }
            bills += '<a target="_blank" href="' + json_data[i].link + queryStr + '">' + json_data[i].bill_number + ": " + json_data[i].title + "</a>\n";
          }
        }
      } else {
        let queryStr = '';

        if (json_data.link && json_data.link.length > 0){
          if (json_data.original_text) {
            queryStr = "&originalText=" + encodeURIComponent(json_data.original_text);
          }
          bills += '<a target="_blank" href="' + json_data.link + queryStr + '">' + json_data.bill_number + ": " + json_data.title + "</a>\n";
        }
      }

      if (bills != '') {
        content += "\n\n<b>This answer contains references to the following bills:</b>\n" + bills;
      }
    }

    return content;
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user-chat/${userId}/0`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        data.forEach(item => {
          if (messageIds.includes(item.id)) {
            return;
          }

          const answerData = JSON.parse(item.answer_text);
          const content = formatResponse(answerData.text_response, answerData.json_data);

          setMessages(prevMessages => [...prevMessages, { content: item.question_text, direction: 'outgoing' }]);
          setMessages(prevMessages => [...prevMessages, { content: content, direction: 'incoming', original: item.original_text }]);
          messageIds.push(item.id);
        });
      })
      .catch(error => console.error('Error fetching user chat data:', error));
  }, [userId]); // Ensure this runs only once on mount and when userId changes

  useEffect(() => {
    let interval;
    if (doPoll) {
      interval = setInterval(() => {
        fetch(`${process.env.REACT_APP_API_URL}/chat-poll/${threadId}/${runId}/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
          }
        })
          .then(response => response.json())
          .then(data => {
            if (data.text_response != null){
              const content = formatResponse(data.text_response, data.json_data);

              setMessages(prevMessages => {
                const newMessages = [...prevMessages];
                newMessages[newMessages.length - 1] = { content: content, direction: 'incoming', original: data.original_text };
                return newMessages;
              });
              setDoPoll(false);
              setPollNum(0);
            }
            else {
              setPollNum(prevPollNum => {
                let newMessage = '';
                const newPollNum = prevPollNum + 1;
                if (newPollNum > 5) {
                  newMessage = "We're still working...";

                  if (newPollNum > 10) {
                    newMessage = "We're still working... This is taking longer than expected. Please be patient.";
                  }

                  setMessages(prevMessages => {
                    const newMessages = [...prevMessages];
                    newMessages[newMessages.length - 1] = { content: newMessage, direction: 'incoming' };
                    return newMessages;
                  });
                }
                return newPollNum;
              });
            }
          })
          .catch(error => console.error('Error polling:', error));
      }, 2000);
    }

    return () => clearInterval(interval);
  }, [doPoll, threadId, runId, userId]);

  return (
    <Layout searchQuery={searchQuery}>
      <Box sx={{ height: '90vh', width: '45vw' }}>
        <MainContainer>
          <ChatContainer>
            <MessageList>
              {messages.map((message, index) => (
                <Tooltip title={message.original || ''} key={index}>
                  <div style={{ width: '600px' }}>
                    <Message
                      model={{
                        message: message.content,
                        sentTime: 'just now',
                        sender: 'User',
                        direction: message.direction,
                      }}
                    />
                  </div>
                </Tooltip>
              ))}
            </MessageList>
            <MessageInput
              placeholder="Type a message..."
              onSend={remainingChats > 0 ? handleSendMessage : () => alert("You've reached your daily max of 4 chats. Your chat allocation will be reset after 24 hours.")}
              attachButton={false}
            />
          </ChatContainer>
        </MainContainer>
        {orgPackage === 'Free' && (
          <Box sx={{ mt: 2 }}>
            <strong>
              Chats Remaining: <span style={{ color: remainingChats === 0 ? 'red' : 'inherit' }}>{remainingChats}</span>
            </strong>
          </Box>
        )}
      </Box>
    </Layout>
  );
}

export default ChatPage;