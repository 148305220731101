import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Box, TextField, Button, IconButton, Typography, Tooltip, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import hasFeatureAccess from './utils/featureAccess';

function TagPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('user_id');
  const [open, setOpen] = useState(false);
  const [dialogTagId, setDialogTagId] = useState(null);

  const handleClickOpen = (dialogTagId) => {
    setDialogTagId(dialogTagId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    createStatement();
    handleClose();
    setStatement('');
  };

  useEffect(() => {
    // Fetch all tags on component mount
    const fetchTags = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag?user_id=${userId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setTags(result);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, [userId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true); // Set loading to true
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
      body: JSON.stringify({
        user: userId,
        tag: tag,
      }),
      });

      if (!response.ok) {
      throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setTags([result, ...tags]); // Add the new tag to the front of the list
      setTag(''); // Clear the input field
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleDelete = async (tagId) => {
    if (!window.confirm('Are you sure you want to delete this tag?')) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag/${tagId}/?user_id=${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setTags(tags.filter(tag => tag.id !== tagId)); // Remove the deleted tag from the list
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const handleGenerate = async (tagId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag/${tagId}/generate-subtags/?user_id=${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setTags([...result, ...tags]); // Add the new subtags to the front of the list
    } catch (error) {
      console.error('Error generating subtags:', error);
    }
  };

  const [statement, setStatement] = useState('');

  const createStatement = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag-statement/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify({
          statement: statement,
          user_tag: dialogTagId,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setTags(tags.map(tag => 
        tag.id === dialogTagId ? { ...tag, statements: [...tag.statements, result] } : tag
      ));

      setDialogTagId(null);
    } catch (error) {
      console.error('Error adding statement:', error);
    }
  };

  const handleDeleteStatement = async (statementId) => {
    if (!window.confirm('Are you sure you want to delete this statement?')) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag-statement/${statementId}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setTags(tags.map(tag => ({
        ...tag,
        statements: tag.statements.filter(statement => statement.id !== statementId),
      })));
    } catch (error) {
      console.error('Error deleting statement:', error);
    }
  };

  return (
    <Layout searchQuery={searchQuery}>
      <Box sx={{ height: '80vh', width: '80vw', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Tags
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
          <TextField
            fullWidth
            label="Tag"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            margin="normal"
            required
            disabled={tags.length >= 5}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading || tags.length >= 5}>
            {loading ? 'Loading...' : 'Add Tag'}
          </Button>
          {tags.length >= 5 && (
            <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
              <strong>You've hit the maximum number of tags allowed. Please contact <a href="mailto:sales@civiclyenvolved.com">sales@civiclyenvolved.com</a> for more information.</strong>
            </Typography>
          )}
        </form>
        <Box sx={{ marginTop: 4, width: '100%' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tag</TableCell>
                  <TableCell>Statement(s)</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tags.map((tag) => (
                  <TableRow key={tag.id} sx={{ verticalAlign: 'top' }}>
                    <TableCell>{tag.parent ? `${tag.parent} - ${tag.tag}` : tag.tag}</TableCell>
                    <TableCell>
                      {tag.statements && tag.statements.map((statement) => (
                        <Typography key={statement.id} variant="body2" sx={{ marginBottom: 1 }}>
                          {statement.statement}
                          <Tooltip title="Click here to delete this statement">
                            <IconButton onClick={() => handleDeleteStatement(statement.id)} size="small">
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      ))}
                      
                      {tag.statements.length === 0 && (
                        <Button variant="contained" color="primary" size="small" onClick={() => handleClickOpen(tag.id)}>
                          Add 
                        </Button>
                      )}
                      <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Add Statement</DialogTitle>
                        <DialogContent>
                          <TextField
                            fullWidth
                            label="Add Statement"
                            value={statement}
                            onChange={(e) => setStatement(e.target.value)}
                            margin="normal"
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose} color="primary">
                            Cancel
                          </Button>
                          <Button onClick={() => handleSave()} color="primary">
                            Save
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Click here to generate additional tags based on this one">
                        <IconButton onClick={() => handleGenerate(tag.id)} size="small">
                          <AutorenewIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Click here to delete this tag">
                        <IconButton onClick={() => handleDelete(tag.id)} size="small">
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>                  
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Layout>
  );
}

export default TagPage;